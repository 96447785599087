.App {
  text-align: center;
  box-sizing: border-box;
  height: 100%;
}

.container {
  display: flex;
  height: 100%;
}
