.folder {
  border: 1px solid black;
  margin: 5px;
  padding: 10px;
}

.folder:hover {
  background: lightblue;
}

.active {
  background-color: lightblue;
}

#folderDelete {
  border: none;
}
