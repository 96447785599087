form,
label,
input {
  margin: 10px;
}

button {
  background: white;
  border: 1px solid black;
  margin: 5px;
  padding: 10px;
  width: 100px;
}

button:hover {
  background: lightblue;
}
