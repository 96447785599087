button {
  border: none;
}

.edit {
  background: white;
  border: none;
  margin: 5px;
  padding: 0px;
  width: 0px;
}
.delete {
  background: white;
  border: none;
  margin: 5px;
  padding: 0px;
  width: 0px;
}

.delete:hover {
  background: lightblue;
}

.note {
  border: 1px solid black;
  margin: 10px 0px;
}

.delete {
  position: relative;
  left: 200px;
  bottom: 50px;
}
