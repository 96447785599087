.addNote {
  background: white;
  border: 1px solid black;
  margin: 5px;
  padding: 10px;
  width: 100px;
}

.addNote:hover {
  background: lightblue;
}

.noteListMain {
  margin: auto auto;
  width: 60%;
}
