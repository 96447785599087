.back {
  border: 1px solid black;
  padding: 20px;
  width: 470px;
}

.button {
  background: white;
  border: 1px solid black;
  margin: 5px;
  padding: 10px;
  width: 100px;
}
