.header {
  border: 1px solid black;
  padding: 30px;
  width: 100%;
}

.headerTitle {
  color: black;
  font-size: 40px;
  position: relative;
  right: 100px;
  text-decoration: none;
}

.headerTitle:visited {
  color: black;
}
